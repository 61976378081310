.faatable,
.faqtable th,
.faqtable td {
    border: 1px solid;
  }

/* .form-check { */
    /* background-color: bisque; */
/* } */


.newticket, 
.newticket section {
  border-radius: 25px;
}

.newticket>form>section {
  background-color: azure;
}

.newticket{
  background-color: #dfe8f1;
}